.navcontain {
    background-color: #ffdf20;
    -webkit-box-shadow: 0px 1px 1px #000000;
    -moz-box-shadow: 0px 1px 1px #000000;
    box-shadow: 0px 1px 2px #000000;
}
.navTitle {
    color: black;
    font-size: 3vh !important;
    padding-left: 10%;
    padding-bottom: 1%;
    font-weight: 500;
}
.navTitle:hover {
    font-weight: 700 !important;
    color: inherit;
}
.nav-link-2:hover {
    text-decoration: underline !important;
    color: blue !important;
}
.nav-brand-2:hover {
    text-decoration: underline !important;
    color: blue !important;
}