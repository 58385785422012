.postCardClass {
    color: black;
    border-color: grey !important;
    border-width: 1px;
    border-radius: 2% !important;
    text-decoration: none !important;
}
.postCardClass:hover {
    color: blue !important;
    border-color: blue !important;
    border-width: 1px;
    border-radius: 2% !important;
    text-decoration: none !important;
}